body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Press Start 2P', cursive;
}

.App {
  text-align: center;
}

html {
  font-size: 80%;
}

body {
  padding: 0 2rem;
}


.main {
  box-shadow: 1rem 1rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.list {

}
.list__item {
  /*  inline-block bg-gray-100 py-1 px-2 m-1 rounded hover:bg-gray-200 cursor-pointer*/
  cursor: pointer;
  display: table;
  font-size: 1rem;
  flex: 1;
}
.list__item:hover {
  color: #8d8d8d;
}


.image {
  image-rendering: pixelated;
  width: 70%;
  aspect-ratio: 1/1;
}

.image--2 {
  display: none;
}
.images {

}
.images:hover .image--1 {
  display: none;
}
.images:hover .image--2 {
  display: inline;
}
